import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Were you ever glad about a tip for a great MTB singletrack? Or were you
despereately in need for a good information? Share your trails, be part of the
Trailguide community and win cool prizes from EVOC!`}</strong></p>
    <Image src="news/evocuserawards2018_1.jpg" mdxType="Image" />
    <p>{`Did you ride and track some nice singletracks this season? Upload your tracks
to `}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{` and you will participate in
the drawing of the EVOC User Awards on November 15th.`}</p>
    <Image src="news/evocuserawards2018_2.jpg" mdxType="Image" />
    <p>{`EVOC provides a nice Stage 12l backpack and two 40l duffle bags for your
next bike rides and trips. The three lucky winners will be drawn of all who
uploaded trails since the last user awards end of August.`}</p>
    <Image src="news/uploadtrails_5.jpg" mdxType="Image" />
    <p>{`Uploading a track is very easy! Almost every GPS device or app can record the
data as a .gpx file. Open `}<a parentName="p" {...{
        "href": "http://www.trailguide.net"
      }}>{`trailguide.net`}</a>{`
on your computer and drag and drop the file into the "Add trail" window.
If needed, cut your loop or ride to cover only the singletrack parts using the
slider below the map. Add additional info and thats it! You uploaded your
track :-)`}</p>
    <Image src="news/uploadtrails_6.jpg" mdxType="Image" />
    <p>{`With everyone contributing a little bit, Trailguide gets better and better
everyday. We are looking forward for your tracks, and with a bit of luck,
your next ride and trip will be with a new backpack or bag!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      